<template>
  <div>
    <c-card class="cardClassDetailForm" :noHeader="true">
      <template slot="card-detail">
        <div class="col-6">
          <c-upload
            :attachInfo="attachInfo1"
            :editable="editable&&!disabled"
            label="MSDS"
            @files="(fileList) => files(fileList, 'MSDS')">
          </c-upload>
        </div>
        <div class="col-6">
          <c-upload
            :attachInfo="attachInfo2"
            :editable="editable&&!disabled"
            label="화학물질 성분내역서"
            @files="(fileList) => files(fileList, 'INGRDIENT')">
          </c-upload>
        </div>
        <div class="col-6">
          <c-upload
            :attachInfo="attachInfo3"
            :editable="editable&&!disabled"
            label="화학물질 확인명세서">
          </c-upload>
        </div>
        <div class="col-6">
          <c-upload
            :attachInfo="attachInfo4"
            :editable="editable&&!disabled"
            label="성적서">
          </c-upload>
        </div>
        <div class="col-6">
          <c-upload
            :attachInfo="attachInfo5"
            :editable="editable&&!disabled"
            label="기타">
          </c-upload>
        </div>
      </template>
    </c-card>
  </div>
</template>

<script>
export default {
  name: 'chem-attach-file',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fileLength: {
      type: Object,
      default: () => ({
        msds: 0,
        ingrdient: 0,
      }),
    },
  },
  data() {
    return {
      editable: true,
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'CHEM_REVIEW_MSDS',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'CHEM_REVIEW_INGREDIENT_STATEMENT',
        taskKey: '',
      },
      attachInfo3: {
        isSubmit: '',
        taskClassCd: 'CHEM_REVIEW_CONFIRMATION_STATEMENT',
        taskKey: '',
      },
      attachInfo4: {
        isSubmit: '',
        taskClassCd: 'CHEM_REVIEW_REPORT',
        taskKey: '',
      },
      attachInfo5: {
        isSubmit: '',
        taskClassCd: 'CHEM_REVIEW_ETC',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$set(this.attachInfo1, 'taskKey', this.popupParam.mdmChemReviewId)
      this.$set(this.attachInfo2, 'taskKey', this.popupParam.mdmChemReviewId)
      this.$set(this.attachInfo3, 'taskKey', this.popupParam.mdmChemReviewId)
      this.$set(this.attachInfo4, 'taskKey', this.popupParam.mdmChemReviewId)
      this.$set(this.attachInfo5, 'taskKey', this.popupParam.mdmChemReviewId)
    },
    files(fileList, type) {
      if (type === 'MSDS') {
        this.$set(this.fileLength, 'msds', fileList.length)
      } else {
        this.$set(this.fileLength, 'ingrdient', fileList.length)
      }
    }
  }
};
</script>